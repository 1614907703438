'use client'
import '@/app/styles/wordpress/WPContent.scss';
import React, {ReactNode, RefObject, useEffect, useState} from "react";
import MediaShareLinks from "@/app/components/atomic/molecules/MediaShareLinks";
import '@/app/styles/wordpress/WPSidebar.scss';
import useIntersectionObserver from "@/app/hooks/useIntersectionObserver";

interface IShareNavigator {
    title: string
    text: string
    url: string
}

export default function PageSidebar({children, hasAboutBlock, hasTOC, shareNavigator, intersectionAnchorRef}: {
    children?: ReactNode | null
    hasAboutBlock?: boolean
    hasTOC?: boolean
    shareNavigator?: IShareNavigator,
    intersectionAnchorRef: RefObject<any>,
}) {
    const [currentUrl, setCurrentUrl] = useState<string>('');

    const {isIntersecting, IntersectionAnchor} = useIntersectionObserver(
        intersectionAnchorRef,
        {
            rootMargin: '0px 0px -100% 0px',
        },
    )

    useEffect(() => {
        setCurrentUrl(window?.location.href);
    }, []);

    return <>
        <div className={"wp-sidebar"}>
            <div className={`lg:sticky md:top-28 lg:flex flex-col w-full lg:w-[300px] gap-12 ${isIntersecting ? 'flex' : 'hidden'}`}>
                {hasTOC && (
                    <div id="sidebar-container" className="border rounded-xl border-gray-100 py-3 px-4 hidden lg:flex lg:flex-col">

                        <div id="toc-container" className="toc-content" />
                    </div>
                )}

                {children}

                <div id="fix-sidebar-content" className="fixed bottom-0 left-0 w-full lg:w-auto lg:relative gap-2 flex flex-col" />
                <div id="sidebar-advertblock-container" className="mt-2 hidden lg:flex lg:flex-col" />

                {hasAboutBlock && (
                    <div className={'hidden lg:flex lg:flex-col lg:gap-4'}>
                        <div className={'flex flex-col p-5 gap-4 rounded-xl bg-blue-200'}>
                            <div
                                className={'text-lg font-semibold'}
                            >
                                À propos de Réassurez-moi
                            </div>

                            <div
                                className={'text-blue-700 text-sm'}
                            >
                                Réassurez-moi.fr est un comparateur d'assurances en ligne qui aide les utilisateurs à trouver les meilleures offres pour divers
                                types d'assurances, notamment habitation, santé, auto et emprunteur.
                            </div>
                        </div>

                        <div className={'text-lg font-semibold'}>Partagez cet article</div>

                        <MediaShareLinks
                            sharedMedia={'facebook, x, linkedin, whatsapp'}
                            shareNavigator={shareNavigator}
                            urlToShare={currentUrl}
                            hasShareButton
                        />
                    </div>
                )}
            </div>
        </div>
    </>
}
